<template>
  <form id="filterResults" class="sticky-top col">

    <div class="row">

      <div class="inputs col-12 col-md-8">
        <label for="filterQuery" class="sr-only">Search by supplier, name, country, city or state</label>
        <input
                type="text"
                id="filterQuery"
                placeholder="Search by supplier name, country, city or state"
                aria-label="Search by supplier name, country or city or state"
                class="form-control"
                :value="filters.query"
                data-name="query"
                @input="setQuery"
        />

      </div>
      <!-- Dropdowns and toggles -->
      <div class="inputs col-12 col-md-4">
        <div class="inputs">

          <!-- Regions -->
          <div class="btn-group" role="group">
            <div class="btn-group" role="group">
              <button class="btn btn-primary btn-round dropdown-toggle"
                      type="button"
                      id="regionMenu"
                      data-toggle="dropdown"
                      data-target="region"
                      aria-haspopup="true"
                      aria-expanded="false"
                      @click="toggleDropdown">
                {{ filters.region }}
              </button>
              <div :class="[{ 'active': showRegions }, 'dropdown-menu']" aria-labelledby="regionMenu">
                <h6 class="dropdown-header">Available regions</h6>
                <a class="dropdown-item" @click="setFilter('region', 'All regions')">All regions</a>

                <div v-for="(countryList, region) in regions" :key="region">
                  <a class="dropdown-item" @click="setFilter('region', region)">{{region}}</a>
                </div>
              </div>
            </div>

            <!-- Countries -->
            <div class="btn-group">
              <button class="btn btn-primary btn-round dropdown-toggle"
                      type="button"
                      id="countryMenu"
                      data-toggle="dropdown"
                      data-target="country"
                      aria-haspopup="true"
                      aria-expanded="false"
                      :disabled="filters.region === 'All regions'"
                      @click="toggleDropdown">

                <template v-if="filters.region === 'All regions'">All countries</template>
                <template v-else-if="filters.country == 'All countries'">All {{filters.region}}</template>
                <template v-else>{{ filters.country }}</template>
              </button>
              <div :class="[{ 'active': showCountries }, 'dropdown-menu']" aria-labelledby="countryMenu"
                   v-if="filters.region !== 'All regions'">

                <h6 class="dropdown-header">Available countries</h6>
                <a class="dropdown-item" @click="setFilter('country', 'All countries')">All {{filters.region}}</a>

                <div v-for="country in regions[filters.region].countries" :key="country">
                  <a class="dropdown-item" @click="setFilter('country', country)">{{country}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="inputs">
          <a @click="toggleMCR">
            <span v-if="toggleOpts.showOnlyMCR" class="tick"></span>
            <span v-else class="square"></span>
            Only show MCR enabled locations
          </a>

        </div>
      </div>


    </div>

  </form>
</template>


<script>

  export default {
    name: 'locations-filter',
    data: function () {
      return {
        searchQuery: '',
        showCountries: false,
        showRegions: false,
        activeDropdown: null,
      };
    },
    props: ['regions', 'filters', 'toggleChange', 'toggleOpts'],
    methods: {
      toggleMCR(e) {
        this.toggleChange('mcr');
      },
      setQuery(e) {
        this.setFilter('query', e.target.value);
      },
      setFilter(key, value) {

        const filter = Object.assign({}, this.filters);

        // reset region on change
        if (key === 'region') {
          filter.country = 'All countries';
        }

        const update = Object.assign({}, {
          ...filter,
          [key]: value
        });

        this.$emit('update', update);
      },

      toggleDropdown(e) {
        if (this.activeDropdown !== null) {
          this.closeModal();
          return true;
        }

        const target = e.target.dataset.target;
        this.activeDropdown = e.target;

        if (target === 'region') {
          this.showRegions = !this.showRegions;
        } else if (target === 'country') {
          this.showCountries = !this.showCountries;
        }

        if (this.showCountries || this.showRegions) {
          this.activeDropdown.addEventListener('mouseout', this.addDropdownListener);
        }
      },

      addDropdownListener() {
        document.addEventListener('click', this.closeModal);
      },

      closeModal() {
        this.showCountries = false;
        this.showRegions = false;
        document.removeEventListener('click', this.closeModal);
        this.activeDropdown.removeEventListener('mouseout', this.addDropdownListener);
        this.activeDropdown = null;
      },

    }
  };
</script>


<style type="scss" scoped>

  .sticky-top {
    top: 60px;
    z-index: 1;
  }

  #filterResults {
    background-color: #fff;
    padding: 20px 1rem 1rem 0;
    /*border-bottom: 1px solid #ccc;*/
    align-items: center;
  }

  .active {
    display: block;
  }

  h6 a {
    font-weight: bold;
  }

  .dropdown-item {
    cursor: pointer;
  }

  .dropdown-item:hover {
    background-color: #ccc;
  }

  .inputs {
    margin-bottom: 1rem;
  }

  .tick {
    background: url(~@fortawesome/fontawesome-free/svgs/solid/check-square.svg) no-repeat;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: text-bottom;
  }

  .square {
    background: url(~@fortawesome/fontawesome-free/svgs/solid/square.svg) no-repeat;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: text-bottom;
  }

  .inputs a:hover {
    cursor: pointer;
  }

</style>
