/**
 * Checks the response status
 * @param response
 * @returns {*}
 */
export function status(response) {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
    } else {
        return Promise.reject(new Error(response.statusText));
    }
}


/**
 * Returns the JSON object
 * @param response
 * @returns {*|Promise<*>|Promise<any>}
 */
export function json(response) {
    return response.json();
}


/**
 * Generic fetch
 * @param url
 * @returns {Promise<any>}
 */
export function fetcher(url) {
    return new Promise((resolve, reject) => {
        fetch(url, { method: 'GET' })
            .then(status)
            .then(json)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
                throw new Error(err);
            });
    });
}


/**
 * Batch fetch
 * @param fetchObj (Object) { key: string, url: url }
 * @returns {Promise<any>}
 */
export function batchFetcher(fetchObj) {
    return new Promise((resolve, reject) => {
        let promises = fetchObj.map( obj => {
            return fetch(obj.url, { method: 'GET' })
            .then(status)
            .then(json)
            .then(res => {
                return { key: obj.key, data: res.data };
            })
            .catch(err => {
                throw new Error(err);
            });
        });

        return Promise.all(promises)
            .then( res => {
                resolve(res);
            })
            .catch( err=> {
                reject(err);
            });
    });
}
