<template>
  <div class="btn-group" role="group">

    <button :class="[ { 'disabled': displayOpts.disabled }, displayOpts.color, displayOpts.size, 'btn', 'btn-round', 'dropdown-toggle']"
            type="button"
            id="dropdown"
            data-toggle="dropdown"
            data-target="region"
            aria-haspopup="true"
            aria-expanded="false"
            data-flip="false"
            @click="toggleDropdown">
      {{selected}}
    </button>

    <div :class="[{ 'active': showDropdown }, 'dropdown-menu']"
         :style="{'column-count': displayOpts.cols}"
         aria-labelledby="dropdown">

      <template v-if="displayOpts.defaultDisabled && displayOpts.displayDefault">
        <a class="dropdown-header disabled">{{displayOpts.defaultSelect}}</a>
      </template>
      <template v-if="!displayOpts.defaultDisabled && displayOpts.displayDefault">
        <a class="dropdown-item" @click="selectItem(displayOpts.defaultSelect)">{{displayOpts.defaultSelect}}</a>
      </template>

      <div v-for="optionKey in optionsSorted" :key="optionKey">
        <a class="dropdown-item" @click="selectItem(optionKey)">{{optionKey}}</a>
      </div>
    </div>

  </div>
</template>

<script>

  /**
   * Bootstrap dropdown
   * @props {options} (array)  array of string
   * @props {update} (function, params)
   * @props {selected} (emitter)
   * @props {defaultSelect} (string) default option (all)
   */

  export default {
    name: 'bootstrap-dropdown',
    props: ['options', 'update', 'selected', 'defaultSelect', 'defaultDisabled', 'defaultOpts', 'disabled'],
    data: function () {
      return {
        activeDropdown: null,
        showDropdown: false,
        selectedValue: '',
        displayOpts: {
          defaultSelect: '',
          defaultDisabled: true,
          displayDefault: true,
          cols: 1,
          size: '',
          color: 'btn-primary',
          sort: true,
          emitKey: true,
          disabled: false
        }
      };
    },
    mounted() {
      this.setDefaults();
    },
    updated() {
      this.setDefaults();
    },
    methods: {
      setDefaults() {
        if (typeof this.defaultOpts !== 'undefined') {
          Object.keys(this.defaultOpts).forEach(key => {
            this.displayOpts[key] = this.defaultOpts[key];
          });
        }
      },
      selectItem(option) {
        this.$emit('update', option);
      },
      toggleDropdown(e) {
        if (this.activeDropdown !== null) {
          this.closeModal();
          return true;
        }

        this.activeDropdown = e.target;
        this.showDropdown = !this.showDropdown;
        this.activeDropdown.addEventListener('mouseout', this.addDropdownListener);
      },
      addDropdownListener() {
        document.addEventListener('click', this.closeModal);
      },
      closeModal() {
        this.showDropdown = false;
        document.removeEventListener('click', this.closeModal);
        this.activeDropdown.removeEventListener('mouseout', this.addDropdownListener);
        this.activeDropdown = null;

      },
    },
    computed: {
      optionsSorted() {
        let results;

        if (Array.isArray(this.options)) {
          results = this.options;
        }
        else {
          results = Object.keys(this.options);
        }

        if (this.displayOpts.sort) {
          return results.sort();
        }

        return results;
      }
    }
  };
</script>

<style type="scss" scoped>
  .active {
    display: block;
    z-index: 10000;
  }

  h6 a {
    font-weight: bold;
  }

  .dropdown-item {
    cursor: pointer;
  }

  .dropdown-item:hover {
    background-color: #ccc;
  }

  button[disabled] {
    cursor: not-allowed;
  }

</style>
