<template>
  <div id="megaportLocations" class="container-fluid">

    <LocationsMap :geojson="geoJSON" :selected-location="selectedLocation" v-if="show.map"></LocationsMap>
    <LocationsListing
            :locations="active.locationsByCountry"
            :regions="active.regions"
            :countries="active.countries"
            v-on:select-location="selectLocation"
            v-if="show.locations && locations.length > 0">
    </LocationsListing>

    <Latency
            :regions="active.regions"
            :locations="active.locationsByCountry"
            v-if="show.latency && locations.length > 0">
    </Latency>

  </div>
</template>

<script>
  /**
   * Locations root component. It manages data for all child components.
   */

  import * as FetchHelper from './../../lib/fetch-helpers';
  import LocationsMap from './LocationsMap';
  import LocationsListing from './LocationsListing';
  import Latency from './../latency/Latency';
  import codedRegionsList from './../../data/coded-regions';
  import 'whatwg-fetch';

  export default {
    name: 'locations',
    props: ['config'],
    data: function () {
      return {
        locations: [],
        rawLocations: {},
        featureCollection: [],
        active: {
          regions: {},
          locationsByCountry: {},
          countries: []
        },
        geoJSON: {
          type: 'FeatureCollection',
          features: []
        },
        show: {
          map: false,
          locations: false,
          latency: false
        },
        selectedLocation: null
      };
    },
    components: {
      LocationsMap,
      LocationsListing,
      Latency
    },
    created() {
      const settings = new Map([['map', this.config.showMap], ['locations', this.config.showLocations], ['latency', this.config.showLatency]]);
      this.toggle(settings);
    },
    mounted() {
      this.getLocations();
    },
    methods: {
      toggle(componentList) {
        componentList.forEach((value, component) => {
          this.show[component] = value;
        });
      },
      getLocations() {
        FetchHelper.fetcher('https://api.megaport.com/v2/locations')
          .then(res => {
            this.rawLocations = res.data;
            this.processRawLocations();
          })
          .catch(err => {
            throw new Error(err);
          });
      },
      processRawLocations() {
        // Generate a list of active regions and locations
        // Generate GeoJSON
        Object.keys(this.rawLocations).forEach(key => {
          const location = Object.assign({}, this.rawLocations[key]);
          this.generateGeoJSON(location);
          this.generateActiveRegionsList(location);
          this.generateActiveLocations(location);
        });
      },
      generateActiveRegionsList(location) {

        // @todo -- fix at a later time: countries are assigned a region
        // @todo -- by matching their market to the list from coded regions.
        // @todo -- Norway has a region of AU in the endpoint because of https://noyaml.com/
        // @todo -- Also UAE says it's in the NL market
        // @todo --
        // @todo -- We can't use the country name, because the API returns USA instead of United States of America
        // @todo -- and UK instead of United Kingdom
        // @todo -- Future developer ... give me a bit of <3

        if (location.country === 'Norway') location.market = 'NO';
        if (location.country === 'United Arab Emirates') location.market = 'AE';


        const region = codedRegionsList[location.market].region;
        const country = location.country;

        // region
        if (typeof this.active.regions[region] === 'undefined') {
          this.active.regions[region] = {
            countries: []
          };
        }

        // country
        if (this.active.regions[region]['countries'].indexOf(country) < 0) {
          this.active.countries.push(country);
          this.active.countries.sort();
          this.active.locationsByCountry[country] = [];
          this.active.regions[region]['countries'].push(country);
          this.active.regions[region]['countries'].sort();
        }

        return region;
      },
      generateActiveLocations(location) {
        const region = codedRegionsList[location.market].region;
        const country = location.country;

        location.region = region;

        this.active.locationsByCountry[country].push(location);
        this.locations.push(location);
      },
      generateGeoJSON(location) {
        const feature = {
          type: 'Feature',
          properties: {
            ...location
          },
          geometry: {
            type: 'Point',
            coordinates: [location.longitude, location.latitude, 0.0]
          }
        };

        // cleanup the properties data
        delete feature.properties.latitude;
        delete feature.properties.longitude;

        this.geoJSON.features.push(feature);
      },
      selectLocation(location) {
        this.selectedLocation = location;
      }
    }
  };

</script>


<style scoped>
  .container-fluid {
    padding: 0px;
  }
</style>
