<template>
  <!--<router-view></router-view>-->
  <main>
    <component v-bind:is="selectedView" :config="config"></component>
  </main>
</template>

<script>

  import IXView from './views/IX';
  import IXStatsView from './views/IXStats';
  import LocationsRoot from './components/locations/LocationsRoot';

  export default {
    name: 'app',
    components: { IXView, IXStatsView, LocationsRoot },
    data: function () {
      return {
        selectedView: null,
        config: {
          showMap: false,
          showLocations: false,
          showLatency: false
        }
      };
    },
    created() {
      if (document.querySelector('#app') !== null) {
        const selectedView = document.querySelector('#app').getAttribute('data-view');

        switch (selectedView) {
          case 'MapView':
            this.selectedView = LocationsRoot;
            this.config = {showMap: true, showLocations: false, showLatency: false};
            break;
          case 'LocationsView':
            this.selectedView = LocationsRoot;
            this.config = {showMap: false, showLocations: true, showLatency: false};
            break;
          case 'MapLocationsView':
            this.selectedView = LocationsRoot;
            this.config = {showMap: true, showLocations: true, showLatency: false};
            break;
          case 'LatencyView':
            this.selectedView = LocationsRoot;
            this.config = {showMap: false, showLocations: false, showLatency: true};
            break;
          case 'IXView':
            this.selectedView = IXView;
            break;
          case 'IXStatsView':
            this.selectedView = IXStatsView;
            break;
          default:
            break;
        }
      }
    }
  };
</script>
