<template>

  <div class="container-fluid">

    <div id="filter" class="flex-row sticky-top">

      <div class="row-fluid" v-if="ixNetwork">

        <div class="col-12 col-md-8 d-inline-flex">
          <label for="searchQuery" class="sr-only">Search by member</label>
          <input type="text"
                 class="form-control"
                 id="searchQuery"
                 placeholder="Search by organisation, AS, location"
                 v-model="selectedFilters.query">
        </div>

        <div class="col-12 col-md-2 d-inline-flex">
          <Dropdown :options="ixpByName"
                    v-on:update="setLocation"
                    :selected="selectedFilters.location"
                    defaultSelect="All locations">
          </Dropdown>
        </div>

        <div class="row results-header">
          <div class="col-9 col-md-4 d-inline-flex">Member</div>
          <div class="col-2 col-md-2 d-inline-flex">AS</div>
          <div class="col-12 col-md-6 d-inline-flex">Location</div>
        </div>

      </div>

    </div>


    <template v-for="member in membersFiltered">
      <div class="member flex-row" :key="member"
           v-if="selectedFilters.location === 'All locations' || membersByName[member].ixp.hasOwnProperty(selectedFilters.location)">
        <div class="col-9 col-md-4 d-inline-flex"><strong>{{member}}</strong></div>
        <div class="col-2 col-md-2 d-inline-flex">{{membersByName[member].asnum}}</div>

        <div class="col-12 col-md-6 d-inline-flex">
          <ul class="list-inline">
            <template v-for="(connection, ixpName) in membersByName[member].ixp">
              <li class="list-inline-item" :key="ixpName">{{ixpName}}</li>
            </template>
          </ul>
        </div>
      </div>
    </template>


  </div>

</template>

<script>

  import * as FetchHelpers from './../../lib/fetch-helpers';
  import Dropdown from './../bootstrap-dropdown';
  import 'whatwg-fetch';

  export default {
    name: 'IX',
    components: {
      Dropdown
    },
    data: function () {
      return {
        ixNetwork: null,
        ixpByName: {},
        ixpByID: {},
        membersByName: {},
        membersSorted: [],
        detailsRow: null,
        selectedFilters: {
          query: '',
          location: 'All locations',
        },
      };
    },
    created() {
      FetchHelpers.fetcher('https://lg.megaport.com/megaport.json')
        .then(res => {
          this.prepareData(res);
        })
        .catch(err => {
          throw new Error(err);
        });
    },
    mounted() {
    },
    methods: {
      prepareData(res) {
        this.ixNetwork = res;

        // IXP by name
        this.ixNetwork.ixp_list.forEach(ixp => {
          this.ixpByName[ixp.name] = ixp;
        });

        // IXP by ID
        this.ixNetwork.ixp_list.forEach(ixp => {
          this.ixpByID[ixp.ixp_id] = ixp;
        });

        // Members by name
        this.ixNetwork.member_list.forEach(member => {
          this.membersByName[member.name] = member;
          this.membersByName[member.name]['ixp'] = {};
          member.connection_list.forEach(cnx => {
            const ixp = this.ixpByID[cnx.ixp_id].name;
            this.membersByName[member.name]['ixp'][ixp] = cnx;
          });
        });

        // Members sorted
        this.membersSorted = this.ixNetwork.member_list.reduce((list, member) => {
          if (list.indexOf(member.name) < 0) {
            list.push(member.name);
          }
          return list;
        }, []).sort();
      },
      setLocation(loc) {
        this.selectedFilters.location = loc;
      },
    },
    computed: {
      membersFiltered() {
        if (this.selectedFilters.query.length > 0) {
          return this.membersSorted.filter(memberName => {
            const member = this.membersByName[memberName];
            if (JSON.stringify(member).toLowerCase().includes(this.selectedFilters.query.toLowerCase())) {
              return memberName;
            }
          });
        }
        return this.membersSorted;
      }
    }

  };
</script>

<style scoped>

  .sticky-top {
    top: 60px;
    z-index: 1;
  }

  #filter {
    background-color: #fff;
    padding-top: 20px;
  }

  #filter input {
    margin-bottom: 1rem;
  }

  .results-header {
    background-color: #ccc;
    color: #000;
    margin: 1rem 0 0 0;
    padding: .25rem;
    font-weight: bold;
  }

  .list-inline li:after {
    content: ","
  }

  .list-inline li:last-child:after {
    content: " "
  }

  .member {
    border-bottom: 1px solid #ccc;
    padding-top: 1rem;
    margin-top: 1rem;
  }
</style>
