<template>
  <div id="locationsMap" class="container-fluid"></div>
</template>

<script>

  import Mapbox from 'mapbox-gl';
  import mapPin from './markers/marker-red.png';
  import 'mapbox-gl/dist/mapbox-gl.css';

  export default {
    name: 'locations-map',
    props: ['geojson', 'selected-location'],

    data: function () {
      return {
        map: null,
        mapboxUser: 'marketingmegaport',
        mapboxStyle: 'cjswg3gfq7qz91fmzoiihvmvf',
        mapboxToken: 'pk.eyJ1IjoibWFya2V0aW5nbWVnYXBvcnQiLCJhIjoiY2pzdjg0dTN0MDRiajQzbHdtbWxxdGViaCJ9.VEGObthGbgcHBRJgHB390Q',
        centerView: [-350.020000, 12.362400],
        zoomLevel: 1.3,
        currentPopover: null,
        flyMaxDuration: 800
      };
    },
    mounted() {
      this.setup();
    },
    watch: {
      selectedLocation: function (loc) {

        this.map.flyTo({
          center: [loc.lng, loc.lat],
          zoom: 14,
          maxDuration: this.flyMaxDuration
        });

        setTimeout(() => {
          const features = this.map.querySourceFeatures('enabledLocations');
          const selectedFeature = features.filter(feat => {
            return feat.properties.id === loc.id;
          });
          selectedFeature.lngLat = [loc.lng, loc.lat];

          this.hidePopover();
          this.generateLocationPopup(selectedFeature);
        }, this.flyMaxDuration);
      }
    },
    methods: {
      setup: function () {
        Mapbox.accessToken = this.mapboxToken;
        this.map = new Mapbox.Map({
          container: 'locationsMap',
          style: `mapbox://styles/marketingmegaport/${this.mapboxStyle}`,
          center: this.centerView,
          zoom: this.zoomLevel,
          attributionControl: false
        });

        // UI
        const nav = new Mapbox.NavigationControl({showCompass: false});
        this.map.addControl(nav, 'top-left');

        this.map.on('load', this.loadImages);
      },

      loadImages: function () {
        this.map.loadImage(mapPin, (error, image) => {
          if (error) throw error;
          this.map.addImage('mapPin', image);
          this.addSource();
          this.addLayers();
          this.addEvents();
        });
      },

      addSource: function () {
        this.map.addSource('enabledLocations', {
          type: 'geojson',
          data: this.geojson,
          cluster: true,
          clusterMaxZoom: 10, // Max zoom to cluster points on
          clusterRadius: 15 // Radius of each cluster when clustering points (defaults to 50)
        });
      },

      addLayers: function () {

        // Cluster shape
        this.map.addLayer({
          id: 'clusterShape',
          type: 'circle',
          source: 'enabledLocations',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': '#000000', //'#EC0000',
            'circle-radius': 10
          }
        });

        // Cluster text
        this.map.addLayer({
          id: 'clusterText',
          type: 'symbol',
          source: 'enabledLocations',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
            'text-allow-overlap': true
          },
          paint: {
            'text-color': '#ffffff'
          }
        });

        // Locations
        this.map.addLayer({
          id: 'megaLocation',
          type: 'symbol',
          source: 'enabledLocations',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-image': 'mapPin',
            'icon-anchor': 'center',
            'icon-allow-overlap': true
          }
        });
      },

      addEvents: function () {

        // Cluster events
        //

        this.map.on('click', 'clusterShape', this.zoomCluster);

        this.map.on('mouseenter', 'clusterShape', (e) => {
          this.hidePopover();
          this.generateClusterPopup(e);
          this.map.getCanvas().style.cursor = 'pointer';
        });

        this.map.on('mouseleave', 'clusterShape', () => {
          this.hidePopover();
          this.map.getCanvas().style.cursor = '';
        });

        // Location events
        //

        this.map.on('mouseenter', 'megaLocation', () => {
          // this.hidePopover();
          // this.generateLocationPopup(e);
          this.map.getCanvas().style.cursor = 'pointer';
        });

        this.map.on('click', 'megaLocation', (e) => {
          // this.hidePopover();
          this.generateLocationPopup(e);
          this.map.getCanvas().style.cursor = 'pointer';
        });

        this.map.on('mouseleave', 'megaLocation', () => {
          // this.hidePopover();
          this.map.getCanvas().style.cursor = '';
        });

      },

      zoomCluster: function (e) {
        const features = this.map.queryRenderedFeatures(e.point, {layers: ['clusterShape']});
        const clusterId = features[0].properties.cluster_id;
        this.map.getSource('enabledLocations').getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err)
            return;

          this.map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: (zoom + 3)
          });
        });
      },

      generateClusterPopup: async function (e) {

        const sourceId = e.features[0].source;
        const clusterId = e.features[0].properties.cluster_id;
        const coordinates = e.features[0].geometry.coordinates.slice();

        let locations = [];
        let cities = [];
        let providers = [];

        this.map.getSource(sourceId).getClusterLeaves(clusterId, 10, 0, (err, features) => {
          if (err) return false;

          features.forEach(feature => {
            const cityName = feature.properties.address.city;
            const countryName = feature.properties.address.country;
            const provider = feature.properties.name;

            providers.push(`<li>${provider}</li>`);

            if (cities.indexOf(cityName) === -1) {
              cities.push(cityName);
            } else {
              return false;
            }

            locations.push(`<li>${cityName}, ${countryName}</li>`);
          });

          const description = `
            <h6>Enabled locations</h6>
            <ul class="list-inline">
              ${locations.join('')}
            </ul>
            <h6>Providers</h6>
            <ul class="list-inline">
              ${providers.join('')}
            </ul>
            <p><strong>Click zooms to city level.</strong></p>
          `;

          const popupObj = {
            coordinates: coordinates,
            description: description
          };

          this.showPopover(popupObj);
        });
      },

      generateLocationPopup: function (e) {

        let coordinates, properties, address;

        if (Array.isArray(e)) { // Handle clicks from other components
          const props = e[0].properties;
          coordinates = e.lngLat;
          properties = props;
          address = JSON.parse(props.address);
        } else {
          coordinates = e.features[0].geometry.coordinates.slice();
          properties = e.features[0].properties;
          address = JSON.parse(properties.address);
        }
        console.log(properties,dcName)
        const dcName = encodeURI(properties.name);
        const description = `
          <h6>${properties.name}</h6>
          <address>
          ${address.street}<br/>
          ${address.city}<br/>
          </address>
          <p><a href=https://megaport.com/contact/sales?loc=${properties.siteCode}&dcName=${dcName}>Enquire about this location</a></p>
          `;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        const popupObj = {
          coordinates: coordinates,
          description: description
        };

        this.showPopover(popupObj);
      },

      showPopover: function (popupObj) {
        this.currentPopover = new Mapbox.Popup()
          .setLngLat(popupObj.coordinates)
          .setHTML(popupObj.description)
          .addTo(this.map);
      },

      hidePopover: function () {
        if (this.currentPopover !== null) this.currentPopover.remove();
      },

    }

  };

</script>

<style scoped>
  .mapboxgl-ctrl-logo {
    opacity: 0.3;
  }

  .mapboxgl-popup {
    width: 200px;
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }

  #locationsMap {
    width: 100%;
    height: 500px;
    background: #4B4B4B;
    margin-bottom: 2rem;
  }
</style>
