<template>
  <div id="megaportIXStats" class="container-fluid">

    <IXStats></IXStats>

  </div>
</template>


<script>

  import IXStats from './../components/IX/IXStats';

  export default {
    name: 'IXView',
    components: {
      IXStats
    }
  };

</script>

<style scoped>
  .container-fluid {
    padding: 0px;
  }
</style>
