<template>
  <div id="megaportIX" class="container-fluid">

    <IX></IX>

  </div>
</template>


<script>

  import IX from './../components/IX/IX';

  export default {
    name: 'IXView',
    components: {
      IX
    }
  };

</script>

<style scoped>
  .container-fluid {
    padding: 0px;
  }
</style>
