<template>
  <div class="container-fluid" v-if="ready">

    <!-- Filter -->
    <div id="filter" class="flex-row sticky-top">

      <!-- Country -->
      <div id="filterButtons" class="col col-md-12">
        <Dropdown :options="locations"
                  v-on:update="setCountry"
                  :selected="selectedFilters.country"
                  :defaultOpts="{ defaultSelect: 'All countries' }">
        </Dropdown>

        <!-- State if available -->
        <template class="col" v-if="selectedFilters.country !== 'All countries' && stateByCountry.length > 0">
          <Dropdown :options="stateByCountry"
                    v-on:update="setState"
                    :selected="selectedFilters.state"
                    :defaultOpts="{ defaultSelect: 'States', cols: 2, defaultDisabled: true, displayDefault: false }">
          </Dropdown>
        </template>

        <!-- City (if no state) -->
        <template class="col" v-if="selectedFilters.country !== 'All countries' && stateByCountry.length === 0">
          <Dropdown :options="cityByCountry"
                    v-on:update="setCity"
                    :selected="selectedFilters.city"
                    :defaultOpts="{ defaultSelect: 'Available cities', cols: 1, defaultDisabled: true }">
          </Dropdown>
        </template>


        <!-- City (if state) -->
        <template class="col"
                  v-if="selectedFilters.country !== 'All countries' && selectedFilters.state !== 'States' && stateByCountry.length > 0">
          <Dropdown :options="cityByState"
                    v-on:update="setCity"
                    :selected="selectedFilters.city"
                    :defaultOpts="{ defaultSelect: 'Available cities', cols: 1, defaultDisabled: true }">
          </Dropdown>
        </template>


        <template class="col" v-if="selectedFilters.locationId !== null">
          <a id="selectedLocation" @click="setLocation(null)" class="btn btn-round btn-info">{{locationsById[selectedFilters.locationId].name}}
            <span class="badge badget-light">Change</span>
          </a>
        </template>

      </div>

      <!-- Search and sort -->
      <div id="searchFilter" class="col col-12" v-if="latencyValuesReady">
        <button type="button" class="btn btn-sm btn-dark btn-round" @click="showSearch = !showSearch">Search</button>

        <Dropdown :options="sortOptions"
                  v-on:update="setSortBy"
                  :selected="selectedFilters.sortByLabel"
                  :defaultOpts="{ displayDefault: false, size: 'btn-sm', color: 'btn-dark', sort: false }">
        </Dropdown>

        <label for="searchQuery" class="sr-only">Search by data centre name</label>
        <input type="text"
               class="form-control"
               id="searchQuery"
               placeholder="Search by name"
               v-if="showSearch"
               v-model="selectedFilters.query">

      </div>


      <!-- Results header -->
      <div id="latencyMessage" class="row" v-if="latencyValuesReady">
        <p>Results shown are averages for each month</p>
      </div>

      <div class="row latency-header" v-if="latencyValuesReady">
        <div class="col-12 col-md-6">Location</div>
        <template v-if="showExtraMonths">
          <div class="col col-xs-2 d-inline-flex">{{this.periods.t2.time.format('MMM YYYY')}}</div>
          <div class="col col-xs-2 d-inline-flex">{{this.periods.t1.time.format('MMM YYYY')}}</div>
        </template>
        <div class="col col-xs-2 d-inline-flex">{{this.periods.t0.time.format('MMM YYYY')}}</div>
      </div>

    </div>


    <!-- Locations in city -->
    <div class="row-fluid"
         v-if="this.selectedFilters.city !== 'Available cities' && this.selectedFilters.locationId === null">
      <div class="col">
        <div class="list-group list-group-flush">
          <h3>Please select a datacentre</h3>
          <template v-for="dc in locationsByCity">

            <a :class="[{ 'list-group-item-info': selectedFilters.locationId === dc.id }, 'list-group-item', 'list-group-item-action']"
               :key="dc.id"
               @click="setLocation(dc.id)">
              {{dc.name}}
            </a>

          </template>
        </div>
      </div>
    </div>


    <!-- No results -->
    <div class="row-fluid" v-if="noLatencyValues">
      <strong class="col">There are no results for this location</strong>
    </div>

    <!-- Results -->
    <template v-if="latencyValuesReady">

      <!-- Sort by alpha -->
      <div class="flex-row" v-if="selectedFilters.sortByLabel === 'Sort by location'">
        <div class="col" v-for="(locationID, locationName) in locationsByNameSorted" :key="locationID">

          <div class="row resultsRow"
               v-if="typeof filteredLocations[locationID] !== 'undefined' && hasValues(locationID)">
            <div class="col-12 col-md-6"><strong>{{locationName}}</strong></div>

            <template v-if="showExtraMonths">
              <div class="col col-xs-2 d-inline-flex">
                <span v-if="typeof t2.values[locationID] !== 'undefined'">
                    {{t2.values[locationID].medianRTT | milliseconds}}
                </span>
              </div>
              <div class="col col-xs-2 d-inline-flex">
                <span v-if="typeof t1.values[locationID] !== 'undefined'">
                    {{t1.values[locationID].medianRTT | milliseconds}}
                </span>
              </div>
            </template>
            <div class="col col-xs-2 d-inline-flex">
              <span v-if="typeof t0.values[locationID] !== 'undefined'">
                    {{t0.values[locationID].medianRTT | milliseconds}}
                </span>
            </div>
          </div>
        </div>
      </div>


      <!-- Sort by latency speed -->
      <div class="flex-row" v-if="selectedFilters.sortByLabel === 'Sort by latency'">
        <div class="col" v-for="locationID in t0.sorted" :key="locationID">

          <div class="row resultsRow"
               v-if="typeof filteredLocations[locationID] !== 'undefined' && hasValues(locationID)">

            <div class="col-12 col-md-6"><strong>{{locationsById[locationID]['name']}}</strong></div>
            <template v-if="showExtraMonths">
              <div class="col col-xs-2 d-inline-flex">
                <span v-if="typeof t2.values[locationID] !== 'undefined'">
                    {{t2.values[locationID].medianRTT | milliseconds}}
                </span>
              </div>
              <div class="col col-xs-2 d-inline-flex">
                <span v-if="typeof t1.values[locationID] !== 'undefined'">
                    {{t1.values[locationID].medianRTT | milliseconds}}
                </span>
              </div>
            </template>

            <div class="col col-xs-2 d-inline-flex">
                <span v-if="typeof t0.values[locationID] !== 'undefined'">
                    {{t0.values[locationID].medianRTT | milliseconds}}
                </span>
            </div>

          </div>
        </div>
      </div>

    </template>
  </div>
</template>


<script>
  import Dropdown from './../bootstrap-dropdown';
  import moment from 'moment';
  import * as FetchHelper from './../../lib/fetch-helpers';
  import 'whatwg-fetch';

  export default {
    name: 'latency',
    components: {
      Dropdown
    },
    data: function () {
      return {
        ready: true,
        latencyValuesReady: false,
        noLatencyValues: false,
        rawLocations: null,
        locationsById: {},
        locationsByName: {},
        showSearch: false,
        showExtraMonths: false,
        t0: {
          sorted: [],
          values: {}
        },
        t1: {
          sorted: [],
          values: {}
        },
        t2: {
          sorted: [],
          values: {}
        },
        periods: {
          t0: null,
          t1: null,
          t2: null
        },
        selectedFilters: {
          query: '',
          region: 'All regions',
          country: 'All countries',
          city: 'Available cities',
          state: 'States',
          sortByValue: 'Sort by latency',
          sortByLabel: 'Sort by latency',
          locationId: null
        },
        dateOffset: 0
      };
    },
    props: ['regions', 'locations'],
    created() {
      this.setupPeriods();
      addEventListener('popstate', this.checkSuperSize);
    },
    mounted() {
      this.createLocationsById();
      const url = window.location.hash.replace('#', '').split('=');

      if (typeof url[0] !== 'undefined' && url[0] === 'locationId') {
        if (url[1].includes('supersizeme')) {
          this.showExtraMonths = true;
          url[1] = url[1].replace('supersizeme', '');
        }
        const locId = url[1];
        const location = this.locationsById[locId];
        this.selectedFilters.locationId = locId;
        this.selectedFilters.region = location.region;
        this.selectedFilters.city = location.address.city;
        this.selectedFilters.state = location.address.state;
        this.selectedFilters.country = location.address.country;
        this.updateLatency();
      }

      // if (typeof this.$route.params.country !== 'undefined') {
      //   this.selectedFilters.country = this.$route.params.country;
      // }
      // if (typeof this.$route.params.city !== 'undefined') {
      //   this.selectedFilters.city = this.$route.params.city;
      // }
      // if (typeof this.$route.params.locationId !== 'undefined') {
      // this.selectedFilters.locationId = this.$route.params.locationId;
      // this.selectedFilters.state = this.locationsById[this.$route.params.locationId].address.state;
      // this.updateLatency();
      // }
    },

    methods: {
      setupPeriods() {

        this.dateOffset = 1;

        // Use previous month data if we're at the start of the month
        const localDate = moment();
        const utcOffsetDate = moment().utcOffset('-24:00');
        if (localDate.month() > utcOffsetDate.month()) {
          this.dateOffset = 2;
        }

        const t0 = new moment().subtract(this.dateOffset, 'month');
        const t1 = new moment().subtract(this.dateOffset+1, 'months');
        const t2 = new moment().subtract(this.dateOffset+2, 'months');

        this.periods.t0 = {
          time: t0,
          year: t0.format('YY'),
          month: t0.format('M')
        };

        // Handle the start of each month
        // if (moment().date() === 1) {
        this.periods.t1 = {
          time: t1,
          year: t1.format('YY'),
          month: t1.format('M')
        };
        // }

        this.periods.t2 = {
          time: t2,
          year: t2.format('YY'),
          month: t2.format('M')
        };
      },
      updateLatency() {
        this.latencyValuesReady = false;
        this.noLatencyValues = false;
        this.t0 = {
          sorted: [],
          values: {}
        };
        this.t1 = {
          sorted: [],
          values: {}
        };
        this.t2 = {
          sorted: [],
          values: {}
        };

        const urls = Object.keys(this.periods).map(periodKey => {
          const period = this.periods[periodKey];
          return {
            key: periodKey,
            url: `https://api.megaport.com/v2/locations/rtt?year=${period.year}&month=${period.month}&srcLocation=${this.selectedFilters.locationId}`
          };
        });

        FetchHelper.batchFetcher(urls)
          .then(res => {
            this.prepareData(res);
          })
          .catch(err => {
            throw new Error(err);
          })
          .finally(() => {
            this.latencyValuesReady = true;
          });
      },
      prepareData(results) {
        results.forEach(latencyObj => {
          const key = latencyObj.key;
          const speeds = latencyObj.data.sort(this.sortBySpeed);

          speeds.forEach(speed => {
            this[key].sorted.push(speed.dstLocation);
            this[key].values[speed.dstLocation] = speed;
          });
        });
        if (this.t0.sorted.length === 0) { //} && this.t1.sorted.length === 0 && this.t2.sorted.length === 0) {
          this.noLatencyValues = true;
        }
      },
      sortBySpeed(speedA, speedB) {
        if (speedA.medianRTT < speedB.medianRTT) {
          return -1;
        } else if (speedA.medianRTT > speedB.medianRTT) {
          return 1;
        }
        return 0;
      },
      setCountry(country) {
        this.latencyValuesReady = false;
        this.selectedFilters = Object.assign({}, {
          ...this.selectedFilters,
          country: country,
          locationId: null,
          state: 'States',
          city: 'Available cities'
        });
        // this.$router.push(`/latency/${this.selectedFilters.country}`);
      },
      setCity(city) {
        this.latencyValuesReady = false;
        this.selectedFilters = Object.assign({}, {
          ...this.selectedFilters,
          city: city,
          locationId: null
        });
        // this.$router.push(`/latency/${this.selectedFilters.country}/${this.selectedFilters.city}`);
      },
      setState(state) {
        this.latencyValuesReady = false;
        this.selectedFilters = Object.assign({}, {
          ...this.selectedFilters,
          state: state,
          city: 'Available cities',
          locationId: null
        });
      },
      setLocation(locationId) {
        this.selectedFilters.locationId = locationId;

        if (locationId !== null) {
          this.updateLatency();
          window.location.hash = `locationId=${locationId}`;
          // this.$router.push(`/latency/${this.selectedFilters.country}/${this.selectedFilters.city}/${this.selectedFilters.locationId}`);
        } else {
          this.latencyValuesReady = false;
        }
      },
      createLocationsById() {
        Object.keys(this.locations).forEach(region => {
          this.locations[region].forEach(loc => {
            this.locationsByName[loc.name] = loc.id;
            this.locationsById[loc.id] = loc;
          });
        });
      },
      setSortBy(value) {
        this.selectedFilters.sortByLabel = value;
        this.selectedFilters.sortByValue = this.sortOptions[value];
      },
      hasValues(locationID) {
        return (
          this.t0.values.hasOwnProperty(locationID) ||
          this.t1.values.hasOwnProperty(locationID) ||
          this.t2.values.hasOwnProperty(locationID)
        );
      },
      checkSuperSize() {
        const url = window.location.hash.replace('#', '').split('=');
        if (url[1].includes('supersizeme')) {
          this.showExtraMonths = true;
        }
        else {
          this.showExtraMonths = false;
        }
      }
    },

    computed: {
      regionsSorted() {
        return Object.keys(this.regions).sort();
      },
      filteredLocations() {
        const locations = {};
        if (this.selectedFilters.query !== '') {
          Object.keys(this.locationsById).forEach(locKey => {
            if (this.locationsById[locKey].name.toLowerCase().includes(this.selectedFilters.query.toLowerCase())) {
              locations[locKey] = this.locationsById[locKey];
            }
          });
          return locations;
        } else {
          return this.locationsById;
        }
      },
      sortedList() {
        return this[this.selectedFilters.sortByValue];
      },
      stateByCountry() {
        const states = new Set();

        if (this.selectedFilters.country !== 'All countries') {
          this.locations[this.selectedFilters.country].forEach(location => {
            if (location.address.state !== null && location.address.state !== '') states.add(location.address.state);
          });
        }
        // Only add a state dropdown where the state count > 7
        if (states.size > 6) {
          return Array.from(states);
        } else {
          return [];
        }
      },
      cityByCountry() {
        const cities = new Set();

        this.locations[this.selectedFilters.country].forEach(location => {
          cities.add(location.address.city);
        });
        return Array.from(cities);
      },
      cityByState() {
        const cities = new Set();

        if (this.selectedFilters.state !== 'States') {
          this.locations[this.selectedFilters.country].forEach(location => {
            if (location.address.state === this.selectedFilters.state) cities.add(location.address.city);
          });
        }
        return Array.from(cities);
      },
      locationsByCity() {
        return this.locations[this.selectedFilters.country].filter(location => {
          return location.address.city === this.selectedFilters.city;
        });
      },
      sortOptions() {

        const options = [
          'Sort by location',
          'Sort by latency'
        ];

        // if (this.t0.sorted.length > 0) options[this.periods.t0.time.format('MMM YY')] = 't0';
        // if (this.t1.sorted.length > 0) options[this.periods.t1.time.format('MMM YY')] = 't1';
        // if (this.t2.sorted.length > 0) options[this.periods.t2.time.format('MMM YY')] = 't2';

        return options;
      },
      locationsByNameSorted() {
        return Object.keys(this.locationsByName).sort();
      }
    },

    filters: {
      milliseconds(value) {
        if (!value) return '';
        return `${value.toFixed(2)} ms`;
      }
    }
  };
</script>

<style scoped>

  .sticky-top {
    top: 60px;
    z-index: 1;
  }

  #filter {
    background-color: #fff;
    padding: 20px 0 0 0;
    margin: 1rem 0;
    /*border-bottom: 1px solid #ccc;*/
  }

  #filterButtons {
    padding-bottom: .25rem;
  }

  #filter .btn {
    margin-right: .25rem;
  }

  #filter .btn-group {
    margin-right: .25rem;
  }

  .resultsRow {
    border-bottom: 1px solid #ccc;
    margin-bottom: .25rem;
    padding-bottom: .25rem;
  }

  .locationsList li a {
    font-weight: normal;
    cursor: pointer;
  }

  .locationsList li a:hover {
    background-color: #ededed;
  }

  #selectedLocation {
    color: #fff;
  }

  .latency-header {
    background-color: #ccc;
    color: #000;
    margin: 1rem 0 0 0;
    padding: .25rem;
    font-weight: bold;
  }

  .latency-header .noBold {
    font-weight: normal;
  }

  #latencyMessage {
    margin: 1rem 0 0 1rem;
  }

  #searchFilter {
    margin-top: 1rem;
  }

  #searchFilter input {
    margin-top: .25rem;
  }

  a {
    cursor: pointer;
  }

</style>
