import Vue from 'vue';
import App from './App.vue';

if (process.env.NODE_ENV === 'development') {
  require('bootstrap/dist/css/bootstrap.min.css');
}

Vue.config.productionTip = false;

new Vue({
  // router,
  render: h => h(App)
}).$mount('#app');
