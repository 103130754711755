<template>
  <div id="ixStats" class="container-fluid">

    <div class="row">
      <div id="ixLocations" class="col-12">
        <button class="btn btn-primary btn-sm btn-round"
                v-for="location in Object.keys(ixListByName).sort()"
                :key="location"
                @click="displayGraph(ixListByName[location].name)">{{ixListByName[location].name}}
        </button>
      </div>
      <div class="col-12">
        <div id="graph">
          <Plotly v-if="!loading" :data="graphData" :options="graphOptions" :layout="graphLayout"></Plotly>
          <div v-if="selectedGraph.location === 'Select a location'" id="pleaseSelect"
               class="d-flex align-items-center">
            <p class="d-flex justify-content-center flex-grow-1">Please select a location</p>
          </div>
          <div id="metaInfo" v-if="selectedGraph.location !== 'Select a location'">
            <ul class="list list-inline">
              <li class="list-inline-item"><strong>ASN</strong> {{ixListByName[selectedGraph.location].asn}}</li>
              <li class="list-inline-item"><strong>IPV4</strong> {{ixListByName[selectedGraph.location].ipv4_network}}</li>
              <li class="list-inline-item"><strong>IPV6</strong> {{ixListByName[selectedGraph.location].ipv6_network}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>

  import * as FetchHelpers from './../../lib/fetch-helpers';
  import Plotly from './../plotly';
  import moment from 'moment/min/moment-with-locales.min';


  export default {
    name: 'IXStats',
    components: {
      Plotly
    },
    data: function () {
      return {
        ixList: [],
        selectedGraph: {
          location: 'Select a location',
          data: null
        },
        graphData: [],
        graphOptions: {},
        graphLayout: {},
        loading: true
      };
    },
    created() {
      FetchHelpers.fetcher('https://api.megaport.com/v2/ixp')
        .then(res => {
          this.ixList = res.data;
        });
    },
    methods: {
      displayGraph(graph) {
        this.loading = true;
        this.selectedGraph.location = graph;
        const url = `https://api.megaport.com/v2/graph/ixAggregate?ixName=${graph}`;
        FetchHelpers.fetcher(url)
          .then(res => {
            this.selectedGraph.data = res.data;
            this.prepareGraphData();
          });
      },
      prepareGraphData() {

        let yAxisOpts = {};
        yAxisOpts = {
          typeMeasurement: 'bps',
          exponentformat: 'SI'
        };
        let multiplier = 1000000;

        const minDate = new Date(this.selectedGraph.data.end);
        const maxDate = new Date(this.selectedGraph.data.start);

        const trace1 = {
          x: [],
          y: [],
          mode: 'lines',
          name: 'In',
          line: {
            color: 'rgb(233,31,47)'
          }
        };

        const trace2 = {
          x: [],
          y: [],
          mode: 'lines',
          name: 'Out',
          line: {
            color: 'rgb(6,121,182)'
          }
        };


        this.graphLayout = {
          title: this.selectedGraph.location,
          xaxis: {
            title: `Date ${moment(minDate).format('DD/MM/YY HH:mm')} - ${moment(maxDate).format('DD/MM/YY HH:mm')}`,
            nticks: 10
          },
          yaxis: {
            ...yAxisOpts
          },
          margin: {
            l: 60,
            r: 60,
            t: 60,
            b: 80,
            autoResize: true,
          },
          legend: {
            orientation: 'h'
          }
        };

        this.graphOptions = {
          responsive: true,
          displaylogo: false,
          displayModeBar: false
        };

        this.selectedGraph.data.in_bps.forEach((bps, idx) => {
          const timestamp = moment(minDate).add(idx * 5, 'minutes').format('HH:mm');
          trace1['x'].push(timestamp);
          trace1['y'].push(bps * multiplier);
        });

        this.selectedGraph.data.out_bps.forEach((bps, idx) => {
          const timestamp = moment(minDate).add(idx * 5, 'minutes').format('HH:mm');
          trace2['x'].push(timestamp);
          trace2['y'].push(bps * multiplier);
        });

        this.graphData = [trace1, trace2];
        this.loading = false;

      }
    },
    computed: {
      ixListByName() {
        const ixList = {};
        this.ixList.forEach(ix => {
          ixList[ix.name] = ix;
        });
        return ixList;
      }
    }
  };

</script>


<style scoped>

  #ixStats {
    margin: 3rem 0 12rem 0;
  }

  #ixLocations {
    text-align: center;
  }

  .list-group a {
    cursor: pointer;
  }

  #pleaseSelect {
    height: 400px;
  }

  #graph {
    width: 100%;
    min-height: 400px;
    height: 400px;
    margin: 1rem 0;
  }


  #ixStats .btn {
    cursor: pointer;
  }

  #ixStats .btn-sm {
    margin: 0 .5rem .5rem 0;
  }

  #metaInfo {
    text-align: center;
  }

</style>
