<template>
  <div id="locationsListing" v-if="ready" class="container-fluid">

    <h2 class="display-4 text-center">Search Megaport Enabled Locations</h2>

    <LocationsFilter
            :regions="regions"
            :filters="selectedFilters"
            :toggleOpts="{ showOnlyMCR }"
            :toggleChange="toggleFilters"
            v-if="ready"
            v-on:update="setFilters">
    </LocationsFilter>


    <section v-for="region in filteredRegion" :key="region">

      <!-- No locations found -->
      <div class="col"
           v-if="totalLocationsInRegion[region].length === 0">
        <p>No location found matching the query <strong>{{selectedFilters.query}}</strong> in {{region}}</p>
      </div>

      <!-- Listings -->
      <template v-if="totalLocationsInRegion[region].length > 0">

        <div v-for="country in regions[region].countries" :key="country" class="col">

          <p v-if="filteredLocations[country].length === 0 && selectedFilters.country === country">
            {{country}} has no MCR enabled locations. <br/>
            Toggle the <strong>Only show MCR enabled locations</strong> button to show the
            <strong>{{locations[country].length}}</strong> Megaport enabled locations in {{country}}.
          </p>

          <template v-if="selectedFilters.country === 'All countries' || selectedFilters.country === country">

            <!-- Header -->
            <h4 v-if="filteredLocations[country].length > 0">{{region}} - {{country}}</h4>

            <div class="location-header row d-none d-md-flex d-lg-flex d-xl-flex"
                 v-if="filteredLocations[country].length > 0">
              <div class="col-12 col-md-4">Location</div>
              <div class="col-12 col-md-3">Address</div>
              <div class="col-12 col-md-1">Port speed</div>
              <div class="col-12 col-md-1">MCR enabled</div>
              <div class="col-12 col-md-2"></div>
            </div>

            <!-- Details -->
            <div class="location row" v-for="loc in filteredLocations[country]" :key="loc.id">
              <div class="col-12 col-md-4 locationName">
                <strong>{{loc.name}}</strong>
                <a :href="`https://megaport.com/contact/sales?loc=${loc.siteCode}&dcName=${loc.name}`" class="d-md-flex d-lg-flex d-xl-flex">
                    <span v-if="loc.status === 'Deployment'" class="badge badge-pill badge-success">
                    Coming soon. Enquire &rsaquo;
                  </span>
                </a>
              </div>
              <div class="col-12 col-md-3">
                <!--<a @click="setMapMarkerEvent(loc)"-->
                <!--class="mapPin markerIcon d-none d-md-inline d-lg-inline d-xl-inline">-->
                <!--<img :src="mapPin"/>-->
                <!--</a>-->
                {{loc.address.street}},
                {{loc.address.suburb}}<span v-if="loc.address.suburb">,</span>
                {{loc.address.city}}, {{loc.address.state}}

              </div>
              <div class="col-1 col-md-1 d-none d-md-inline d-lg-inline d-xl-inline">
                <span class="badge badge-light"
                      v-if="loc.status !== 'Restricted'">
                  {{loc.products.megaport.join('/')}}Gbps
                </span>
              </div>
              <div class="col-1 col-md-1 d-none d-md-inline d-lg-inline d-xl-inline">
                <span v-if="loc.products.mcr2 || loc.products.mcr1">
                  <i class="tick"></i>
                </span>
              </div>
              <div class="col-2 col-md-2 d-none d-md-inline d-lg-inline d-xl-inline">
                <a :href="`/network-latency#locationId=${loc.id}`"
                   class="btn btn-sm btn-round btn-link">
                  View latency
                </a>
              </div>

              <!-- mobile only details -->
              <div class="col-12 d-sm-flex d-xs-flex row d-md-none">

                <span class="col">
                  <i class="tachometer"></i> {{loc.products.megaport.join('/')}}Gbps
                </span>

                <a :href="`/network-latency#locationId=${loc.id}`"
                   class="btn btn-sm btn-primary btn-round">
                  View latency
                </a>

                <!--                <span class="badge badge-pill badge-info col"-->
                <!--                      v-if="loc.products.mcr && loc.status !== 'Restricted'">MCR-->
                <!--                </span>-->
              </div>
              <div class="col-12 d-sm-flex d-xs-flex row d-md-none">
                <span class="col" v-if="loc.products.mcr2 || loc.products.mcr1">
                  <i class="tick"></i> MCR Enabled
                </span>
              </div>
            </div>

          </template>

        </div>
      </template>

    </section>


  </div>
</template>


<script>

  import codedRegionsList from './../../data/coded-regions';
  import LocationsFilter from './LocationsFilter';
  import mapPin from './markers/marker-grey.png';
  import dashboardImg from './markers/dashboard.svg';

  export default {
    name: 'locations-listing',
    data: function () {
      return {
        ready: true,
        selectedFilters: {
          query: '',
          region: 'All regions',
          country: 'All countries',
          city: 'Select country',
        },
        showOnlyMCR: false,
        mapPin: mapPin,
        dashboardImg: dashboardImg
      };
    },
    props: ['locations', 'regions', 'countries'],
    components: {LocationsFilter},
    created() {
      if (location.hash.includes('mcr')) {
        this.showOnlyMCR = true;
      }
    },
    methods: {
      setup: async function () {
        await this.locations.forEach(location => {

          const region = codedRegionsList[location.market];

          location.region = region;
          location.regionName = region.region;

          // Region
          const regionName = region.region;
          if (typeof this.locationsByRegion[regionName] === 'undefined') {
            this.locationsByRegion[regionName] = {};
            this.regions.push(regionName);
          }

          // Country
          const country = location.country;
          if (typeof this.locationsByRegion[regionName][country] === 'undefined') {
            this.locationsByRegion[regionName][country] = [];
            this.countriesList.push(country);
            this.locationsByCountry[country] = [];
            this.citiesByCountry[country] = [];
          }

          // Location
          this.locationsByRegion[regionName][country].push(location);
          this.locationsByCountry[country].push(location);

          // City
          const city = location.address.city;
          if (this.citiesByCountry[country].indexOf(city) === -1) {
            this.citiesByCountry[country].push(city);
          }

        });

        this.ready = true;
        this.regions = this.regions.sort();
      },
      setFilters(val) {
        this.selectedFilters = Object.assign({}, val);
      },
      toggleFilters(filter) {
        if (filter === 'mcr') {
          this.showOnlyMCR = !this.showOnlyMCR;
        }
      }
    },
    computed: {
      regionsSorted() {
        return Object.keys(this.regions).sort();
      },
      mcrLocations() {
        if (this.showOnlyMCR) {
          const filtered = {};
          this.countries.forEach(country => {
            filtered[country] = this.locations[country].filter(location => {
              return (location.products.mcrVersion === 1 || location.products.mcrVersion === 2);
            });
          });
          return filtered;
        }
        return this.locations;
      },
      filteredLocations() {
        if (this.selectedFilters.query.length > 0) {
          const filtered = {};
          const query = this.selectedFilters.query.toLowerCase();
          this.countries.forEach(country => {
            filtered[country] = this.mcrLocations[country].filter(location => {
              return (
                JSON.stringify(location).toLowerCase().includes(query)
              );
            });
          });
          return filtered;
        }
        return this.mcrLocations;
      },
      totalLocationsInRegion() {
        const regionTotals = {};
        this.regionsSorted.forEach(region => {
          regionTotals[region] = this.regions[region].countries.filter(location => {
            if (this.filteredLocations[location].length > 0) {
              return location;
            }
          });
        });

        return regionTotals;
      },
      filteredRegion() {
        if (this.selectedFilters.region !== 'All regions') {
          return this.regionsSorted.filter(region => {
            return this.selectedFilters.region === region;
          });
        }
        return this.regionsSorted;
      },

    }

  };
</script>

<style scoped>

  .tick {
    background: url(~@fortawesome/fontawesome-free/svgs/solid/check.svg);
    width: 15px;
    height: 15px;
    display: inline-block;
  }

  .tachometer {
    background: url(~@fortawesome/fontawesome-free/svgs/solid/tachometer-alt.svg);
    width: 15px;
    height: 15px;
    display: inline-block;
  }

  .display-4 {
    font-size: 2.5rem;
  }

  .location-header {
    background-color: #ccc;
    color: #000;
    margin-bottom: 1rem;
    padding: .25rem;
    font-weight: bold;
  }

  .location {
    border-bottom: 1px solid #ccc;
    padding-bottom: .5rem;
    margin-bottom: .5rem;
  }

  h4 {
    margin: 2rem 0;
  }

  .markerIcon {
    cursor: pointer;
  }

  .mapPin {
    padding-right: 1rem;
  }

  .locationName strong {
    margin-right: .5rem;
  }

</style>
